import styled from '@emotion/styled';
import { Input, Tooltip } from '@mui/material';
import axios from 'axios';
import { m } from 'framer-motion';
import { usePartnerToken } from 'hooks/usePartnerToken';
import { useValidatePostcode } from 'hooks/useValidatePostcode';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useMemo } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useAddressStore, useEnergyExpertsStore, useGenericStore } from 'store';
import { mediaQuery } from 'theme/energy_experts';

import { Button } from 'components/Button';
import { InfoIcon } from 'components/icons/InfoIcon';
import { InfoText } from 'components/InfoText';
import { LocationIcon } from 'components/redesign/LocationIcon';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { getFlagValue } from 'utils/flags/getFlagValue';
import { stepFadeInTransitionProps } from 'utils/framerMotion';
import { JOB_TYPES_MAP_TO_WHITE_LIST_FLAG } from 'utils/jobTypes';
import { trackEvent as _trackEvent } from 'utils/tracking';
import { shouldForwardProp } from 'utils/transientStyled';
import {
  getAvailabilityInterval,
  getFilledSlotsToRender,
  useFetchAvailability,
} from 'utils/useServiceAvailability';

import { constructPath } from '../utils/product/constructPath';
import { extractOutcode } from './utils';

const HomeownersOnlyEnExInfo = () => (
  <HomeOwnersContainer>
    <InfoText
      variant="success"
      topMargin={20}
      className="energyExpertsSelectTime"
    >
      <InfoContainer>
        <InfoIcon color="#1E6937" width={16} height={16} />
        <p style={{ fontSize: 14, fontWeight: 400, marginLeft: 6 }}>
          Energy Experts are currently available to homeowners only.
        </p>
      </InfoContainer>
    </InfoText>
  </HomeOwnersContainer>
);

export const Postcode = ({
  isBulkSlotRatingsEnabled,
}: {
  isBulkSlotRatingsEnabled: boolean;
}) => {
  const router = useRouter();
  const { setState: setEnergyExpertsData } = useEnergyExpertsStore();
  const { setState: setGenericStoreData, userCanEditDetails } =
    useGenericStore();
  const [postcode, setState] = useAddressStore((state) => [
    state.postcode,
    state.setState,
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const context = useExistingStepContext();
  const fetchAvailability = useFetchAvailability();
  void usePartnerToken();

  const {
    currentStep,
    productConfig: {
      numberOfWeeksAvailabilityToShow: numberOfWeeksToShow,
      leadTimeDays,
      fallbackEnabled,
      fallbackSlotThreshold,
      id: productId,
      temporaryJobType: jobType,
      useGenericStoreData,
      postcodeDenyList,
      postcodeAllowList,
      showHomeownersOnlyInfo,
    },
  } = context;

  const setStoreData = useGenericStoreData
    ? setGenericStoreData
    : setEnergyExpertsData;

  const { nextStep } = currentStep.options;

  const form = useForm({
    mode: 'all',
    defaultValues: { postcode },
  });
  const {
    field: { onChange },
  } = useController({
    name: 'postcode',
    control: form.control,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const postcodeUppercase = e.target.value.toUpperCase();
    onChange(postcodeUppercase);
    setState({ postcode: postcodeUppercase });
  };

  const trackEvent = () => {
    if (!postcode) return;
    const outcode = extractOutcode(postcode);
    _trackEvent('Postcode submitted', {
      outcode,
    });
  };

  const { validatePostcode, isTooltipOpen, setIsTooltipOpen, tooltipMessage } =
    useValidatePostcode();

  const { startDateTime, endDateTime, cutOff } = useMemo(
    () => getAvailabilityInterval(numberOfWeeksToShow, leadTimeDays),
    [numberOfWeeksToShow, leadTimeDays],
  );

  const checkPostcodeWhitelist = async () => {
    const useWhitelistValidationEnabled = await getFlagValue(
      'useWhitelistValidation',
      true,
    );

    if (useWhitelistValidationEnabled) {
      if (!postcode) return false;
      const postcodeOutcode = extractOutcode(postcode);
      const useWhitelistEE = JOB_TYPES_MAP_TO_WHITE_LIST_FLAG[jobType] ?? true;

      const {
        data: { isValid },
      } = await axios.post(
        `/api/validate-postcode?postcodeOutcode=${postcodeOutcode}&useWhitelistEE=${useWhitelistEE}`,
        {
          postcodeAllowList,
          postcodeDenyList,
        },
      );
      return isValid;
    }
    return true;
  };

  const checkAvailability = async () => {
    if (!postcode) return;

    const realAvailability = await fetchAvailability({
      postcode,
      startDateTime: cutOff,
      endDateTime,
      jobType,
      isBulkSlotRatingsEnabled,
      isConsolidatedAvailabilityEnabled: true,
      isAvailabilityFallbackEnabled: fallbackEnabled,
      fallbackSlotThreshold,
      productId,
    });
    const slotsToRender = getFilledSlotsToRender({
      startDate: startDateTime,
      endDate: endDateTime,
      cutOff,
      realAvailability,
      isConsolidatedAvailabilityEnabled: true,
      isAvailabilityFallbackEnabled: fallbackEnabled,
      fallbackSlotThreshold,
    });
    return slotsToRender.slots.some((slot) => slot.isAvailable);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    const isPostcodeValid = await validatePostcode(postcode);
    if (!isPostcodeValid) {
      setIsSubmitting(false);
      return;
    }

    setStoreData({ jobType, productId });

    const isPostcodeWhitelisted = await checkPostcodeWhitelist();
    if (!isPostcodeWhitelisted) {
      router.push(constructPath(router, 'area-unserved'));
    } else if (fallbackEnabled) {
      navigateAndTrackEvent();
    } else {
      const areSlotsAvailable = await checkAvailability();
      if (areSlotsAvailable) {
        navigateAndTrackEvent();
      } else {
        router.push(constructPath(router, 'no-availability'));
      }
    }
  };

  const navigateAndTrackEvent = () => {
    router.push(nextStep);
    trackEvent();
  };

  return (
    <OuterContainer {...stepFadeInTransitionProps}>
      <InnerContainer>
        <FormProvider {...form}>
          <FormContainer onSubmit={form.handleSubmit(onSubmit)}>
            <PostcodeContainer>
              <Tooltip
                title={tooltipMessage()}
                placement="bottom-start"
                open={isTooltipOpen}
                onClose={() => setIsTooltipOpen(false)}
              >
                <PostcodeInput
                  disableUnderline
                  placeholder="Enter your postcode"
                  fullWidth
                  startAdornment={<LocationIcon height="20" width="14" />}
                  value={postcode}
                  onChange={handleChange}
                  disabled={!userCanEditDetails}
                />
              </Tooltip>
            </PostcodeContainer>
            {showHomeownersOnlyInfo && <HomeownersOnlyEnExInfo />}
            <AlignButton>
              <Button
                type="submit"
                variant="bookingFlowCTA"
                busy={isSubmitting}
              >
                Next
              </Button>
            </AlignButton>
          </FormContainer>
        </FormProvider>
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(m.div, {
  shouldForwardProp,
})`
  display: flex;
  flex-flow: column;
  align-items: center;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background-color: transparent;

  @media (${mediaQuery('tablet')}) {
    background-color: transparent;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 990px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  overflow: hidden;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  margin-top: 250px;
  padding-top: 5px;
  background-color: transparent;

  @media (${mediaQuery('tablet')}) {
    height: 480px;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.06);
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    margin-top: 16px;
    padding: ${({ theme }) => theme.app.margin.massiveMargin};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  }
`;

const FormContainer = styled.form`
  height: fit-content;
  width: inherit;

  @media (${mediaQuery('tablet')}) {
    width: unset;
  }
`;

const PostcodeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
  justify-content: space-between;
  height: 64px;
  margin-bottom: 120px;
  padding: ${({ theme }) => theme.app.padding.smallPadding};
  padding-left: ${({ theme }) => theme.app.padding.mediumPadding};
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  border: 2px solid ${({ theme }) => theme.app.border.focus};
  background-color: ${({ theme }) => theme.app.background.white};

  @media (${mediaQuery('tablet')}) {
    align-items: center;
    width: 448px;
    padding: ${({ theme }) => theme.app.padding.mediumPadding};
    margin-bottom: ${({ theme }) => theme.app.padding.smallPadding};
    box-shadow: unset;
  }
`;

const PostcodeInput = styled(Input)`
  .MuiInput-input {
    padding: 10px 10px 11px 20px;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};

    @media (${mediaQuery('tablet')}) {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    }

    &::placeholder {
      font-family: ${({ theme }) => theme.app.fontSettings.fontFamily};
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
      line-height: 1.75;
      letter-spacing: -0.04em;
      color: ${({ theme }) => theme.app.text.primary};
      font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};

      @media (${mediaQuery('tablet')}) {
        font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
        line-height: 1.33;
      }

      @media (${mediaQuery('desktop')}) {
        font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
      }
    }
  }
`;

const AlignButton = styled.div`
  @media (${mediaQuery('tablet', false, true)}) {
    height: 47px;
    width: 100%;
    button {
      height: 47px;
      width: 100%;
    }
  }

  @media (${mediaQuery('tablet')}) {
    width: 100%;
    padding: 0 ${({ theme }) => theme.app.padding.smallPadding};
    margin-top: ${({ theme }) => theme.app.margin.largeMargin};
    display: flex;
    align-items: start;
  }

  @media (${mediaQuery('wideMobile')}) {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: unset;
  }
`;

const HomeOwnersContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  text-align: center;
  justify-content: center;

  @media (${mediaQuery('tablet')}) {
    margin-bottom: ${({ theme }) => theme.app.padding.smallPadding};
  }
`;

const InfoContainer = styled.div`
  display: flex;
`;
