import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

import { RedirectLandingPage } from 'components/redesign/RedirectLandingPage';
import { useNullableProductContext } from 'pages/[productType]/[rateDomain]/[step]';

const GridSystem = styled.main`
  display: grid;
  grid-template-columns: repeat(12, minmax(16px, 1fr));
  grid-auto-rows: min-content;
  gap: 0 8px;
  min-height: 100vh;

  @media (${mediaQuery('tablet')}) {
    grid-template-columns: repeat(12, minmax(38px, 1fr));
    gap: 0 24px;
  }

  @media (${mediaQuery('desktop')}) {
    grid-template-columns: repeat(12, minmax(52px, 1fr));
    gap: 0 32px;
  }
`;

const LayoutContainer = styled(GridSystem)<{
  $backgroundColor?: string;
  $mobileBackgroundColor?: string;
}>`
  * {
    font-family: ${({ theme }) => theme.app.fontSettings.fontFamily};
  }
  position: relative;
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  background-color: ${({ $mobileBackgroundColor = '#F5F5F5' }) =>
    $mobileBackgroundColor};

  @media (${mediaQuery('tablet')}) {
    background-color: ${({ theme, $backgroundColor }) =>
      $backgroundColor ?? theme.app.background.layoutContainerDesktop}
  `;

type EnergyExpertsLayoutProps = {
  children: React.ReactNode;
  mobileBackgroundColor?: string;
};

const EnergyExpertsLayout = ({
  children,
  mobileBackgroundColor,
}: EnergyExpertsLayoutProps) => {
  const context = useNullableProductContext();

  const { flowDisabledOptions } = context.productConfig ?? {};
  if (context.productConfig?.residentPortalRedirectsTo) {
    return <RedirectLandingPage />;
  }
  return (
    <LayoutContainer
      role="main"
      $mobileBackgroundColor={mobileBackgroundColor}
      $backgroundColor={
        flowDisabledOptions?.isFlowDisabled ? 'none' : undefined
      }
    >
      {children}
    </LayoutContainer>
  );
};

export default EnergyExpertsLayout;
