import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Fragment } from 'react';

import { useNullableStepContext } from 'pages/[productType]/[rateDomain]/[step]';

import { OvoLogo } from '../OvoLogo';
interface RedirectLandingPageProps {
  redirectTo: string;
}
const REDIRECT_DELAY = 8000;
const DEFAULT_REDIRECT_TO = 'https://www.ovoenergy.com/annual-boiler-service';
export const RedirectLandingPage = () => {
  const theme = useTheme();
  const context = useNullableStepContext();

  const redirect =
    context.productConfig?.residentPortalRedirectsTo || DEFAULT_REDIRECT_TO;
  const overwriteRedirectText =
    context.productConfig?.residentPortalRedirect?.body;
  const overwriteRedirectDelay =
    context.productConfig?.residentPortalRedirect?.delay;

  setTimeout(() => {
    window.location.href = redirect;
  }, overwriteRedirectDelay ?? REDIRECT_DELAY);
  return (
    <>
      <OuterContainer>
        <InnerContainer>
          <OvoLogo color={theme.app.messages.logo} width="80" height="80" />
          {!overwriteRedirectText ? (
            <Typography variant="h3">
              Looking for help with your heating and home repairs? We&apos;ll
              automatically redirect you to our service or repair pages in 8
              seconds.
              <br />
              <br />
              Or click{' '}
              <a className="marketplace-link" href={redirect}>
                here
              </a>{' '}
              for our range of boiler cover options.
            </Typography>
          ) : (
            <Typography variant="h3">
              {overwriteRedirectText.map((item) => {
                if (item.text === '\\n') {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Fragment>
                      <br />
                      <br />
                    </Fragment>
                  );
                }
                return item.url ? (
                  <a href={item.url} className="marketplace-link">
                    {item.text}{' '}
                  </a>
                ) : (
                  item.text + ' '
                );
              })}
            </Typography>
          )}
        </InnerContainer>
        <FooterContainer>
          <TermsText>
            <p>
              OVO is a trading name of CORGI HomePlan Ltd, a firm authorised and
              regulated by the Financial Conduct Authority under firm reference
              number 824122 to carry on insurance distribution. CORGI HomePlan
              Ltd is registered in Scotland (Company No. SC358475). Registered
              Office: Cadworks, 41 West Campbell Street, Glasgow, G2 6SE. CORGI
              HomePlan Ltd is part of OVO Group Ltd.
            </p>
          </TermsText>
        </FooterContainer>
      </OuterContainer>
    </>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  grid-column: 1 / -1;
  background-color: ${({ theme }) => theme.app.background.white};
  text-align: center;
`;

const InnerContainer = styled.div`
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;

  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight['light']};
  color: ${({ theme }) => theme.app.text.secondary};
  padding: 0 24px 0;
  a {
    cursor: pointer;
  }
`;

const TermsText = styled.section<{ $isOVO?: boolean }>`
  padding: ${({ theme }) => theme.app.margin.largeMargin} 0
    ${({ theme }) => theme.app.margin.massiveMargin};
  border-top: 1px solid #b9c0d0;
  border-bottom: 1px solid #b9c0d0;

  p {
    text-align: left;

    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    line-height: ${({ theme }) => theme.app.fontSettings.fontSize[20]};

    a {
      color: ${({ theme }) => theme.app.text.primary};
    }
  }
`;
